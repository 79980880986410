import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout';
import Seo from "../components/seo";
import ShareButtons from "../components/share-buttons"


export default function Post({ data }) {
  const { frontmatter, html } = data.markdownRemark;

    return (
        <Layout>
            <Seo title = {frontmatter.title}/>
            <div className="font-lora">
                <div className="text-2xl font-bold">{frontmatter.title}</div>
                <div className="text-xl">{frontmatter.date}</div>
                <br/>
                <ShareButtons slug={frontmatter.slug}/>
                <br/>
            </div>
            <div
                className="font-lora prose-lg"
                dangerouslySetInnerHTML={{ __html: `<div> ${html} </div>` }}
            />
        </Layout>
    )
}

export const query = graphql`
query BlogPostBySlug($slug: String!) {
  markdownRemark(frontmatter: {slug: {eq: $slug}}) {
    frontmatter {
      date(formatString: "MMMM DD YYYY")
      slug
      tag
      title
    }
    excerpt(pruneLength: 250)
    html
  }
}
`;