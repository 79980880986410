import React from "react"
import 
{FacebookShareButton,
TwitterShareButton, 
EmailShareButton,
LinkedinShareButton,
WhatsappShareButton,
FacebookIcon,
TwitterIcon,
EmailIcon,
LinkedinIcon,
WhatsappIcon,
} 
from "react-share";

export default function ShareButtons({slug}) {
    return(
        <div>
            <FacebookShareButton className="mr-2" url={"https://anfmr.ro" + slug}>
                <FacebookIcon size={36} round={true}/>
            </FacebookShareButton>
            <TwitterShareButton className="mr-2" url={"https://anfmr.ro" + slug}>
                <TwitterIcon size={36} round={true}/>
            </TwitterShareButton>
            <LinkedinShareButton className="mr-2" url={"https://anfmr.ro" + slug}>
                <LinkedinIcon size={36} round={true}/>
            </LinkedinShareButton>
            <WhatsappShareButton className="mr-2" url={"https://anfmr.ro" + slug}>
                <WhatsappIcon size={36} round={true}/>
            </WhatsappShareButton>
            <EmailShareButton className="mr-2" url={"https://anfmr.ro" + slug}>
                <EmailIcon size={36} round={true}/>
            </EmailShareButton>
        </div>
    )
}